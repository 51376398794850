import { FC, useState, useEffect, ReactNode } from "react";
import { Trans, t } from "@lingui/macro";
import Link from "next/link";
import { Text } from "@otrium/atoms";
import { Box, Flex, useBreakpoints } from "@otrium/core";
import {
  HomepageCategorySectionData,
  HomepageSingleCategoryProduct,
} from "src/types/graphql.d";
import { Carousel } from "src/molecules/Carousel";
import { ProductCardWishlist } from "src/organisms/ProductCardWishlist";
import {
  StyledCuratedCategoryHeader,
  StyledImageContainer,
  StyledImage,
  StyledViewAll,
  StyledTitleWrapper,
} from "./CuratedCategory.styled";
import { formatName } from "src/utils/formatName";
import { useSegmentProductList } from "src/hooks/useSegmentProductList";
import { CarouselOverlay } from "src/molecules/CarouselOverlay";
import { SEGMENT_LIST_FORMAT, SEGMENT_LIST_TYPE } from "src/segment";
import { useImageHeight } from "src/hooks/useImageHeight";
import { useTheme } from "@emotion/react";
import { GatedPopupTrackingProps } from "src/types/GatedPopup";
import { useIsLoggedIn } from "src/hooks/useIsLoggedIn";
import { useFeatureFlags } from "src/hooks/useFeatureFlags";
import { useLingui } from "@lingui/react";
import { LoginRegisterModal } from "../LoginRegisterModal";
import {
  removeStorageItem,
  setStorageJSON,
} from "src/utils/helper/localStorage";
import { GATED_POPUP_REDIRECT_DATA } from "src/constants/gatedItem";
import { ProductRedirect } from "src/organisms/ProductCardWishlist/ProductCardWishlist";
import { ChevronTextSmall } from "src/icons/ChevronTextSmall";

interface CuratedCategoryHeaderTitleProps {
  title: string;
  subTitle: string;
}

const LinkWrapper: FC<{
  href?: string;
  children: ReactNode;
  onClick?: () => void;
}> = ({ href, children, onClick }) => {
  return href ? (
    <Link href={href} passHref>
      {children}
    </Link>
  ) : (
    <Box as={"a"} onClick={onClick}>
      {children}
    </Box>
  );
};

const CuratedCategoryHeaderTitle: FC<CuratedCategoryHeaderTitleProps> = ({
  title,
  subTitle,
}) => {
  return (
    <Box pb={[3, 3, 3, 0, 0]} pr={[3, 3, 3, 0, 0]}>
      {subTitle && (
        <Text
          as="h3"
          sx={{
            color: "#999",
            fontWeight: "normal",
            fontSize: "20px",
            textTransform: "uppercase",
          }}
        >
          {formatName(subTitle)}
        </Text>
      )}
      <Flex>
        <Text mb={[0, 0, 0, 2]} as="h3" sx={{ textTransform: "uppercase" }}>
          {formatName(title)}
          <ChevronTextSmall />
        </Text>
      </Flex>
    </Box>
  );
};

interface CuratedCategoryHeaderProps {
  title: string;
  subTitle: string;
  href?: string;
  as?: string;
  category: HomepageCategorySectionData;
  viewAllOnClick?: () => void;
  isGated?: boolean;
}

const CuratedCategoryHeader: FC<CuratedCategoryHeaderProps> = ({
  title,
  subTitle,
  href,
  category,
  viewAllOnClick,
  isGated,
}) => {
  const theme = useTheme();
  const [isMounted, setIsMounted] = useState(false);
  const { isMobile } = useBreakpoints();

  // NOTE: Source: https://github.com/vercel/next.js/issues/2473#issuecomment-587551234
  useEffect(() => setIsMounted(true), []);

  return (
    <LinkWrapper href={href} onClick={isGated ? viewAllOnClick : undefined}>
      <StyledCuratedCategoryHeader mb={16}>
        <StyledImageContainer>
          {isMounted && (
            <StyledImage
              className="lazyload"
              src={
                isMobile ? category.banner_image_mobile : category.banner_image
              }
              alt={title}
            />
          )}
        </StyledImageContainer>

        <StyledTitleWrapper>
          <CuratedCategoryHeaderTitle title={title} subTitle={subTitle} />
          <StyledViewAll
            hasUnderline
            onClick={viewAllOnClick}
            color={theme.colors.tone.white}
          >
            <Trans>View all</Trans>
          </StyledViewAll>
        </StyledTitleWrapper>
      </StyledCuratedCategoryHeader>
    </LinkWrapper>
  );
};

interface CuratedCategoryProps {
  genderId: string;
  category: HomepageCategorySectionData;
  slidesDesktop?: number;
  slidesTablet?: number;
  slidesMobile?: number;
  trackingData?: GatedPopupTrackingProps;
}

const CuratedCategory: FC<CuratedCategoryProps> = ({
  category,
  genderId,
  slidesDesktop,
  slidesTablet,
  slidesMobile,
  trackingData,
}) => {
  const [imageElementWrapperHeight, getImageElementWrapper] = useImageHeight();
  const [open, setOpen] = useState<boolean>(false);
  const isLoggedIn = useIsLoggedIn();
  const { enableGatedHomepage } = useFeatureFlags();
  const { i18n } = useLingui();

  const listFormat = SEGMENT_LIST_FORMAT.carousel;
  const listId = category.title;
  const listType = SEGMENT_LIST_TYPE.curatedCategory;
  const { pageName, shopType, carouselType } =
    (trackingData as GatedPopupTrackingProps) || {};

  const { segmentProductOnClick, setVisibleProducts, segmentViewAllOnClick } =
    useSegmentProductList({
      listFormat,
      listId,
      listType,
      numberOfItems: category?.products?.length || 0,
      shopType: genderId,
    });

  const viewAllLink = category.url
    ? "http://localhost:3000/product-categorie/heren/sportkleding"
    : `/collections/${genderId}/${category.slug}`;

  const allProducts = category.products?.length
    ? !isLoggedIn && enableGatedHomepage
      ? [
          ...category.products.slice(0, -1),
          {
            slug: "last-tile",
            id: "last-tile",
            name: t(i18n)`Log in`,
            brand: {
              name: t(i18n)`Log in to unlock even more fashion brands`,
            },
            thumbnail: "/static/images/carousel-last-card.gif",
          },
        ]
      : category.products
    : [];

  const handleViewAllClick = () => {
    if (!isLoggedIn && enableGatedHomepage) {
      setOpen(true);
    } else {
      segmentViewAllOnClick();
    }
  };

  const setRedirectData = (
    type: string,
    product?: ProductRedirect,
    actionSource?: string
  ) => {
    removeStorageItem(GATED_POPUP_REDIRECT_DATA);
    setStorageJSON(GATED_POPUP_REDIRECT_DATA, {
      gatedActionType: type,
      numberOfItems: category.products?.length || 0,
      product,
      productListMeta: {
        listFormat,
        listId,
        listType,
        shopType: genderId,
      },
      actionSource: actionSource || "view_all",
    });
  };
  const showGatedBehaviour = enableGatedHomepage ? isLoggedIn : true;

  return (
    <Box backgroundColor="#FFFFFF">
      <CuratedCategoryHeader
        title={category.title}
        subTitle={category.subtitle}
        href={showGatedBehaviour ? viewAllLink : undefined}
        category={category}
        viewAllOnClick={handleViewAllClick}
        isGated={!isLoggedIn && !!enableGatedHomepage}
      />
      {category.products && (
        <Carousel
          imageElementWrapperHeight={imageElementWrapperHeight}
          items={allProducts.map((product, index) => {
            if (
              allProducts &&
              index === allProducts.length - 1 &&
              showGatedBehaviour
            ) {
              return (
                <CarouselOverlay
                  key={product.id}
                  link={viewAllLink}
                  onClick={segmentViewAllOnClick}
                >
                  <ProductCardWishlist
                    centerText={true}
                    key={product.id}
                    position={index}
                    product={product as HomepageSingleCategoryProduct}
                    segmentProductOnClick={segmentProductOnClick}
                    setVisibleProducts={setVisibleProducts}
                    showLabels={false}
                    showName={false}
                    showSizes={false}
                    zoomImageOnHover={true}
                    getImageElementWrapper={getImageElementWrapper}
                    trackingData={trackingData}
                  />
                </CarouselOverlay>
              );
            }
            return (
              <ProductCardWishlist
                centerText={true}
                key={product.id}
                position={index}
                product={product as HomepageSingleCategoryProduct}
                segmentProductOnClick={segmentProductOnClick}
                setVisibleProducts={setVisibleProducts}
                showLabels={false}
                showName={false}
                showSizes={false}
                zoomImageOnHover={true}
                redirectLink={viewAllLink}
                getImageElementWrapper={getImageElementWrapper}
                trackingData={trackingData}
                setProductRedirect={setRedirectData}
              />
            );
          })}
          slidesDesktop={slidesDesktop}
          slidesTablet={slidesTablet}
          slidesMobile={slidesMobile}
          marginSizeMobile="l"
        />
      )}
      {open && (
        <LoginRegisterModal
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          redirectTo={viewAllLink}
          trackingData={{
            pageName,
            shopType,
            carouselType,
            actionSource: "view_all",
            redirectTo: viewAllLink,
          }}
          onRedirect={setRedirectData}
        />
      )}
    </Box>
  );
};

export default CuratedCategory;
