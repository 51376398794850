import { useContext, JSX, useState, useCallback } from "react";
import { useRouter } from "next/router";
import { Heading } from "@otrium/atoms";
import { Box, Flex, useBreakpoints } from "@otrium/core";
import { t, Trans } from "@lingui/macro";
import Link from "next/link";
import { Content } from "src/atoms/Content";
import { ExploreMoreCarousel } from "src/molecules/ExploreMoreCarousel";
import { HomeBrandCover } from "src/molecules/HomeBrandCover";
import {
  HomepageDepartmentSection,
  HomepageShopSection,
  ShopType,
} from "src/types/graphql.d";
import { HyperLink } from "src/atoms/HyperLink";
import getAllBrandsPageRoute from "src/utils/getAllBrandsPageRoute";
import { AppCtx } from "src/contexts/app.context";
import { useLingui } from "@lingui/react";
import { useFeatureFlags } from "src/hooks/useFeatureFlags";
import { useIsLoggedIn } from "src/hooks/useIsLoggedIn";
import { LoginRegisterModal } from "src/molecules/LoginRegisterModal";
import {
  removeStorageItem,
  setStorageJSON,
} from "src/utils/helper/localStorage";
import { GATED_POPUP_REDIRECT_DATA } from "src/constants/gatedItem";
import {
  SEGMENT_LIST_FORMAT,
  SEGMENT_PROMOTION_TYPE,
  SEGMENT_LIST_TYPE,
} from "src/segment";
import { ChevronTextSmall } from "src/icons/ChevronTextSmall";

interface Props {
  shops: HomepageShopSection[];
  department?: HomepageDepartmentSection;
  genderId: string;
  order?: number;
}

const BrandsSection = ({
  shops,
  department,
  genderId,
  order,
}: Props): JSX.Element => {
  const { i18n } = useLingui();
  const { locale } = useContext(AppCtx);
  const { asPath: path, push } = useRouter();
  const { enableGatedHomepage } = useFeatureFlags();
  const { isMobile } = useBreakpoints();

  const isLoggedIn = useIsLoggedIn();
  const [open, setOpen] = useState<boolean>(false);
  const [actionSource, setActionSource] = useState<string>("");
  const featuredBrand = shops?.find(({ type }) => type === "featured_shops")
    ?.data?.[0];
  const allBrandsLink = getAllBrandsPageRoute({
    shopType: genderId as ShopType,
    locale,
  });

  const isGated =
    !isLoggedIn &&
    !!enableGatedHomepage &&
    (featuredBrand?.gate?.level === "fully_gated" ||
      featuredBrand?.gate?.level === "partially_gated");
  const handleViewAllClick = useCallback(
    () => {
      if (isGated) {
        setOpen(true);
        setActionSource("feature_brand_view_all");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isGated]
  );

  const setRedirectData = (type: string) => {
    removeStorageItem(GATED_POPUP_REDIRECT_DATA);
    setStorageJSON(GATED_POPUP_REDIRECT_DATA, {
      gatedActionType: type,
      promo_id: featuredBrand?.id,
      promo_name: featuredBrand?.name || featuredBrand?.subtitle,
      promo_creative: featuredBrand?.image,
      promo_position: "homepage-featured-shop-1",
      promo_type: SEGMENT_PROMOTION_TYPE.featuredShop,
      promo_path: path,
      productListMeta: {
        listFormat: SEGMENT_LIST_FORMAT.carousel,
        listId: featuredBrand?.slug,
        listType: SEGMENT_LIST_TYPE.brand,
        shopType: genderId,
      },
    });
  };

  return (
    <Box
      pt={["space32", "space32", "space64", "space64"]}
      pb={["space16", "space16", "space32", "space32"]}
      as="section"
      order={order}
    >
      {featuredBrand && (
        <>
          <Content>
            <Flex
              mx={[0, 0, 0, -3]}
              justifyContent="space-between"
              alignItems="center"
              flexWrap="wrap"
            >
              <Heading
                as="h3"
                fontFamily="PlusJakartaSans"
                fontSize={[2, 2, 3, 3]}
                mb={3}
                color="Black"
                sx={{
                  lineHeight: 1.25,
                  textTransform: "none",
                }}
                onClick={
                  isGated ? handleViewAllClick : () => push(allBrandsLink)
                }
              >
                {isGated
                  ? t(i18n)`Unlock the best fashion deals`
                  : t(i18n)`Brands`}
                {isMobile && <ChevronTextSmall role="presentation" />}
              </Heading>

              {!isMobile && (
                <Box pb={[3, 3, 3, 3, 0]} sx={{ zIndex: 2 }}>
                  {isGated ? (
                    <HyperLink hasUnderline asLink onClick={handleViewAllClick}>
                      <Trans>View all</Trans>
                    </HyperLink>
                  ) : (
                    <Link href={allBrandsLink} passHref legacyBehavior>
                      <HyperLink
                        hasUnderline
                        asLink
                        data-testid="view-all-brands"
                      >
                        <Trans>View all</Trans>
                      </HyperLink>
                    </Link>
                  )}
                </Box>
              )}
            </Flex>
          </Content>
          <Box mb={[32, 32, 32, 64, 64]}>
            <HomeBrandCover
              brand={featuredBrand}
              genderId={genderId}
              isComingSoon={!!featuredBrand.is_coming_soon}
              isGated={isGated}
              isMobile={isMobile}
              setRedirectData={setRedirectData}
            />
          </Box>
        </>
      )}

      <Box>
        <ExploreMoreCarousel
          hideTitle={!featuredBrand}
          shops={shops}
          department={department}
          genderId={genderId}
        />
      </Box>
      {open && (
        <LoginRegisterModal
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          redirectTo={allBrandsLink}
          trackingData={{
            pageName: "homepage",
            shopType: genderId,
            actionSource,
            redirectTo: allBrandsLink,
          }}
          onRedirect={setRedirectData}
        />
      )}
    </Box>
  );
};

export default BrandsSection;
