import AllBrandsSearch from "src/organisms/AllBrandsSearch";
import {
  GetStaticPathsResult,
  GetStaticPropsContext,
  GetStaticPropsResult,
} from "next";
import { HomeView } from "src/organisms/HomeView";
import getConfig from "next/config";

type HomePageProps = {
  pageProps: {
    isAllBrands: boolean;
    slug: string;
    locale: string;
  };
};

const genderTranslations = [
  "women",
  "femme",
  "damen",
  "dames",
  "kobiety",
  "mujer",
  "donna",
  "dame",
  "kvinna",
  "kids",
  "enfant",
  "kinder",
  "kids",
  "ninos",
  "bambini",
  "dzieci",
  "born",
  "barn",
  "men",
  "harren",
  "herren",
  "homme",
  "heren",
  "hombre",
  "uomo",
  "man",
  "mezczyzni",
];

const allBrandsTranslations = [
  "brands",
  "marques",
  "marken",
  "merken",
  "marki",
  "marcas",
  "marche",
  "brand",
  "maerker",
  "varumarken",
];

function HomePage({
  pageProps: { isAllBrands, slug, locale },
}: HomePageProps): JSX.Element {
  // Route for all "/brands"
  if (isAllBrands) {
    return <AllBrandsSearch genderId={slug} locale={locale} />;
  }

  return <HomeView />;
}

export function getStaticPaths(): GetStaticPathsResult {
  return {
    paths: [],
    fallback: "blocking",
  };
}

export function getStaticProps({
  params,
}: GetStaticPropsContext<{
  slug: string;
}>): GetStaticPropsResult<HomePageProps["pageProps"]> {
  if (!params) {
    return {
      notFound: true,
    };
  }

  const { serverRuntimeConfig } = getConfig();
  const isAllBrands = allBrandsTranslations.includes(params.slug);
  const isValidGender = genderTranslations.includes(params.slug);

  if (!isAllBrands && !isValidGender) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      isAllBrands,
      slug: params.slug,
      locale: serverRuntimeConfig.LOCALE,
    },
  };
}

export default HomePage;
