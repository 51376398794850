import { FC, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { Text } from "@otrium/atoms";
import { Box, Flex } from "@otrium/core";
import { Button } from "src/atoms/Button";
import { PromotionEventType, useSegment } from "src/hooks/useSegment";
import {
  SEGMENT_IN_VIEW_THRESHOLD,
  SEGMENT_POSITIONS,
  SEGMENT_PROMOTION_TYPE,
} from "src/segment";
import { StyledImageContainer, StyledImage } from "./PromoBlock.styled";

interface PromoBlockProps {
  title: string;
  subtitle?: string;
  description: string;
  link?: string;
  buttonText?: string;
  code?: string;
  image?: string;
  position?: SEGMENT_POSITIONS;
  promotionType?: SEGMENT_PROMOTION_TYPE;
  gated?: boolean;
}

const PromoBlock: FC<PromoBlockProps> = ({
  title,
  subtitle,
  description,
  link,
  buttonText,
  code,
  image,
  position,
  promotionType,
  gated,
}) => {
  const { segmentPromotionClickedOrViewed } = useSegment();

  const [ref, inView] = useInView({
    threshold: SEGMENT_IN_VIEW_THRESHOLD,
    triggerOnce: true,
  });

  const trackingData = {
    creative: image,
    name: title || subtitle,
    position,
    promotionType,
    gated,
  };

  useEffect(() => {
    if (inView) {
      void segmentPromotionClickedOrViewed({
        ...trackingData,
        eventType: PromotionEventType.view,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const onClickHandler = () =>
    void segmentPromotionClickedOrViewed({
      ...trackingData,
      eventType: PromotionEventType.click,
    });

  return (
    <Flex
      flex={1}
      bg="#fff"
      flexDirection={["column", "column", "column", "unset", "unset"]}
      data-testid="promo-block"
      ref={ref}
    >
      <Box flex={1}>
        <StyledImageContainer>
          <StyledImage className="lazyload" src={image} alt={title} />
        </StyledImageContainer>
      </Box>
      <Flex flex={1}>
        <Flex
          flex={1}
          flexDirection="column"
          m={[24, 24, 24, "48px 64px", "48px 64px"]}
        >
          <Flex flex={1} justifyContent="space-between" flexDirection="column">
            {subtitle && (
              <Text
                as="h3"
                fontSize={[16, 16, 16, 18, 18]}
                sx={{
                  textTransform: "none",
                  fontWeight: "bold",
                  letterSpacing: 1,
                }}
              >
                {subtitle}
              </Text>
            )}
            {title && (
              <Text
                as="h2"
                fontSize={[22, 22, 22, 32, 32]}
                margin={["8px 0 24px", "8px 0 24px", "8px 0 24px", 0, 0]}
                sx={{
                  textTransform: "none",
                  fontWeight: "bold",
                  letterSpacing: 1,
                }}
              >
                {title}
              </Text>
            )}
            <Text fontSize="16px" lineHeight="24px" mb={[8, 8, 8, 0, 0]}>
              {description}
            </Text>
          </Flex>
          <Flex flex={1} flexDirection="column-reverse" alignItems="flex-start">
            {buttonText && link && (
              <Box mt="8px">
                <Button
                  as="a"
                  onClick={onClickHandler}
                  variant="secondary"
                  href={link}
                >
                  {buttonText}
                </Button>
              </Box>
            )}
            {code && (
              <Box backgroundColor="tone.silver" p="8px 16px">
                <Text
                  as="span"
                  color="tone.black"
                  fontSize="16px"
                  lineHeight="24px"
                  fontWeight="600"
                >
                  {code}
                </Text>
              </Box>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PromoBlock;
