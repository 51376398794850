import { useEffect, useContext, useMemo, useState, useCallback } from "react";
import { useSession } from "next-auth/client";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import { t } from "@lingui/macro";
import { Box, Flex } from "@otrium/core";
import { AppCtx } from "src/contexts/app.context";
import { googleTagManager } from "src/tracking/googleTagManager";
import { localeFix } from "src/utils/genderLocale/genderLocale";
import {
  PopularCategory,
  HomepagePopularCategoryData,
  ProductLabelFilter,
  ImageBannersSection,
} from "src/types/graphql.d";
import { getI18nRoute } from "src/utils/i18nRoutes";
import {
  useHomepage,
  useNewInHomepage,
  useShopsHomepage,
} from "src/hooks/useHomepage";
import { useIsLoggedIn } from "src/hooks/useIsLoggedIn";
import { setGender } from "src/modules/page";
import Error from "pages/_error";
import { Content, ContentMobileFullscreen } from "src/atoms/Content";
import { PageHead } from "src/molecules/PageHead";
import { HomeViewSkeleton } from "src/molecules/HomeViewSkeleton";
import { PromotionBanner } from "src/molecules/PromotionBanner";
import { BrandCarousel } from "src/molecules/BrandCarousel";
import { ProductCarousel } from "src/molecules/ProductCarousel";
import { CategoryCarousel } from "src/molecules/CategoryCarousel";
import { CuratedCategory } from "src/molecules/CuratedCategory";
import { PromoBlock } from "src/molecules/PromoBlock";
import { RetroRevival } from "src/molecules/RetroRevival";
import { theme } from "src/theme";
import { SEGMENT_POSITIONS, SEGMENT_PROMOTION_TYPE } from "src/segment";
import { BrandsSection } from "src/organisms/BrandsSection";
import {
  SupportedKeys,
  SUPPORTED_KEYS,
} from "src/molecules/ExploreMoreCarousel/ExploreMoreCarousel";
import Card from "src/molecules/Card/Card";
import { AuthModule } from "../AuthModule";
import getImageWithUpdatedParameters from "src/utils/getImageWithUpdatedParameters";
import { RRPFootnote } from "src/molecules/RRPFootnote";
import { useLingui } from "@lingui/react";
import { HomePageType } from "src/types/HomePageType.d";
import { useFeatureFlags } from "src/hooks/useFeatureFlags";
import NoSSR from "src/atoms/NoSSR";
import { sanitizeString } from "../ProductOrder/utils";
import { filterExpiredImageBannerDeals } from "src/utils/filterExpiredImageBannerDeals";

const HomeView = (): JSX.Element => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const router = useRouter();
  const [session] = useSession();
  const { locale, setHomepageMounted, setPageViewedProperties } =
    useContext(AppCtx);
  const localeGenderId = localeFix({ gender: router.query.slug as string });
  const isLoggedIn = useIsLoggedIn();
  const [isClient, setIsClient] = useState(false);

  const genderId = router.query.slug as string;

  const { enableGatedHomepage } = useFeatureFlags();

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    dispatch(setGender(localeGenderId));
    setHomepageMounted(true);
    setPageViewedProperties({
      page_type: "home",
    });
  }, [dispatch, setHomepageMounted, localeGenderId, setPageViewedProperties]);

  useEffect(() => {
    if (session?.user.email) {
      googleTagManager.trackHomePage(session?.user.email);
    }
  }, [session?.user.email]);

  const { error, data, loading } = useHomepage({
    gender: localeGenderId,
  });

  const {
    data: newInData,
    error: newInError,
    loading: newInLoading,
    refetch: refetchNewIn,
  } = useNewInHomepage({
    gender: localeGenderId,
    limit: 15,
    labels: [ProductLabelFilter.NewIn],
  });

  const {
    data: shopsData,
    error: shopsError,
    loading: shopsLoading,
    refetch: refetchShops,
  } = useShopsHomepage({
    gender: localeGenderId,
  });

  useEffect(() => {
    try {
      void refetchNewIn();
      void refetchShops();
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchNewIn, refetchShops]);

  const promotionBannerSection = data?.homepage?.PromotionBanners;
  const hasPromotionBanner =
    promotionBannerSection?.data?.length &&
    promotionBannerSection?.data?.length >= 1;
  const promotionBanner = promotionBannerSection?.data?.[0];
  const imageBanners = data?.homepage?.ImageBanners;
  const shops = shopsData?.homepage?.Shops;
  const department = data?.homepage?.Departments?.[0];
  const categories = data?.homepage?.Categories;
  const actionBlock = data?.homepage?.ActionBlocks?.[0];
  const inspirationalSection = data?.homepage?.InspirationalSections?.[0];
  const newIn = newInData?.homepage?.productBlocks?.[0];
  const popularCategories = data?.homepage?.PopularCategories?.[0];
  const referFriend = data?.homepage?.ReferFriend?.[0];

  const showSection = useCallback(
    (gated: boolean) => isLoggedIn || (enableGatedHomepage ? !gated : true),
    [isLoggedIn, enableGatedHomepage]
  );

  const brandsSectionPosition = useMemo(() => {
    const orders = [
      ...(shops ?? [])
        .filter((shop) => {
          if (shop.type === "featured_shops" && shop.data.length > 0) {
            return true;
          }

          return (
            shop.data.length >= 3 &&
            SUPPORTED_KEYS.includes(shop.type as SupportedKeys)
          );
        })
        .map((shop) => shop.position),
    ];

    if (department) {
      orders.push(department.position);
    }

    return Math.min(...orders);
  }, [shops, department]);

  const imageBannerItems = useMemo((): ImageBannersSection => {
    if (!imageBanners || imageBanners.length === 0) {
      return {} as ImageBannersSection;
    }

    const filteredBrandsData =
      filterExpiredImageBannerDeals(imageBanners[0].data).filter((item) =>
        showSection(item.gated || false)
      ) || [];

    return { ...imageBanners[0], data: filteredBrandsData };
  }, [imageBanners, showSection]);

  if (
    (!!data || !!newInData || !!shopsData) &&
    !!error &&
    !!newInError &&
    !!shopsError
  ) {
    return <Error />;
  }

  const isLoading: boolean =
    (!data || !newInData || !shopsData) &&
    (loading || newInLoading || shopsLoading);

  const categoryCarouselData = popularCategories?.data.filter((i) =>
    Boolean(i.thumbnail)
  ) as PopularCategory[] | HomepagePopularCategoryData[];

  const showInspirationalSection =
    inspirationalSection &&
    inspirationalSection.data?.length &&
    showSection(inspirationalSection.data.every(({ gated }) => gated));

  const description = t(
    i18n
  )`Explore all the fashion for ${genderId} at Otrium. Shop high-quality clothing, shoes, and accessories from well-known brands with discounts up to 70%!`;

  return (
    <>
      <Box mt={isLoading ? 3 : 0} minHeight="100vh">
        <PageHead
          title={t(i18n)`Otrium | Online Designer Outlet`}
          deeplink={`otrium://${localeGenderId}`}
          description={description}
          enableIndexation
        />
        {isLoading && isClient ? (
          <HomeViewSkeleton />
        ) : (
          <NoSSR>
            <Flex overflow="hidden" flexDirection="column" as="main">
              {!!hasPromotionBanner &&
                promotionBanner &&
                showSection(!!promotionBanner?.gated) && (
                  <Box
                    pt={[0, 0, 0, 0, 0]}
                    pb={[3, 3, 4, 4, 4]}
                    mx={["-20px", "-20px", "-20px", 0, 0]}
                    order={promotionBannerSection.position}
                    as="section"
                  >
                    <Content>
                      <NoSSR>
                        <PromotionBanner promotionBanner={promotionBanner} />
                      </NoSSR>
                    </Content>
                  </Box>
                )}

              {!isLoggedIn && session && enableGatedHomepage && (
                <Box
                  pt={[0, 0, 0, 0, 0]}
                  pb={[3, 3, 4, 4, 4]}
                  mx={["-20px", "-20px", "-20px", 0, 0]}
                  order={2}
                  as="section"
                >
                  <Content>
                    <AuthModule
                      trackingData={{
                        pageType: HomePageType.GATED,
                        categorySlug: undefined,
                        shopType: localeGenderId,
                        pageName: `${localeGenderId} homepage`,
                      }}
                    />
                  </Content>
                </Box>
              )}
              {imageBannerItems && imageBannerItems.data ? (
                <>
                  <Box
                    key={imageBannerItems.position}
                    pb={5}
                    order={imageBannerItems.position}
                  >
                    <ContentMobileFullscreen>
                      <BrandCarousel
                        genderId={localeGenderId}
                        brands={imageBannerItems.data}
                        slidesDesktop={3}
                        slidesTablet={3}
                        slidesMobile={1.5}
                        orderByNew={true}
                        disableTranslation={true}
                        hasVerticalPadding={false}
                        promotionType={SEGMENT_PROMOTION_TYPE.imageBanners}
                        pageName="homepage"
                      />
                    </ContentMobileFullscreen>
                  </Box>
                </>
              ) : null}
              {shops && (
                <NoSSR>
                  <BrandsSection
                    data-testid="homepage_brand_section"
                    key={localeGenderId} // key added intentionally. Forces remounting on the component on gender change
                    order={
                      isFinite(brandsSectionPosition)
                        ? brandsSectionPosition
                        : 0
                    }
                    shops={shops}
                    department={department}
                    genderId={localeGenderId}
                  />
                </NoSSR>
              )}
              {categories &&
                categories.map((category) => {
                  if (category.data.banner_image) {
                    return (
                      <Box
                        key={category.position}
                        py={5}
                        order={category.position}
                        as="section"
                      >
                        <ContentMobileFullscreen>
                          <CuratedCategory
                            genderId={genderId}
                            category={category.data}
                            slidesDesktop={6}
                            slidesTablet={3.5}
                            slidesMobile={2.99}
                            trackingData={{
                              shopType: localeGenderId,
                              pageName: "homepage",
                              carouselType: category.data.title,
                            }}
                          />
                        </ContentMobileFullscreen>
                      </Box>
                    );
                  } else {
                    if (category.data.products) {
                      return (
                        <Box
                          key={category.position}
                          py={5}
                          order={category.position}
                          as="section"
                        >
                          <ContentMobileFullscreen>
                            <ProductCarousel
                              title={category.data.title}
                              subTitle={category.data.subtitle}
                              products={category.data.products}
                              headerLinkHref={
                                category.data.url
                                  ? category.data.url
                                  : `/collections/${genderId}/${category.data.slug}`
                              }
                              slidesDesktop={6}
                              slidesTablet={3.7}
                              slidesMobile={2.99}
                              zoomImageOnHover={true}
                              marginSizeMobile="l"
                              genderId={localeGenderId}
                              trackingData={{
                                shopType: localeGenderId,
                                pageName: "homepage",
                                carouselType: category.data.title,
                              }}
                            />
                          </ContentMobileFullscreen>
                        </Box>
                      );
                    } else {
                      return null;
                    }
                  }
                })}
              {newIn?.data?.is_enabled && newIn?.data?.products?.length && (
                <Box
                  data-testid="homepage_new_in_section"
                  key={newIn.position}
                  order={newIn.position}
                  bg="tone.sand"
                  pt={["space24", "space24", "space32", "space64"]}
                  pb={["space48", "space48", 40, 56]}
                  as="section"
                >
                  <ContentMobileFullscreen>
                    <ProductCarousel
                      title={t(i18n)`New In`}
                      subTitle={
                        newIn?.data?.personalised
                          ? session?.user.name
                            ? t(
                                i18n
                              )`The latest styles for ${session?.user.name}`
                            : t(i18n)`The latest styles for you`
                          : t(i18n)`The latest (and greatest) styles`
                      }
                      products={newIn.data.products}
                      headerLinkHref={newIn.data.url}
                      slidesDesktop={5}
                      slidesTablet={3.5}
                      slidesMobile={2.99}
                      marginSizeMobile="l"
                      zoomImageOnHover={true}
                      showInfoText
                      overlayBgColor={theme.colors.tone.sand}
                      isPersonalised={newIn?.data?.personalised}
                      genderId={localeGenderId}
                      responseSourceType={newIn.data.response_source_type}
                      trackingData={{
                        shopType: localeGenderId,
                        pageName: "homepage",
                        carouselType: "New in",
                      }}
                    />
                  </ContentMobileFullscreen>
                </Box>
              )}

              {actionBlock && !inspirationalSection && (
                <Box
                  py={5}
                  mx={["-20px", "-20px", "-20px", 0, 0]}
                  order={actionBlock.position}
                  backgroundColor="tone.sand"
                  as="section"
                >
                  <Content>
                    {actionBlock.data.code ? (
                      <PromoBlock
                        title={actionBlock.data.subtitle}
                        subtitle={actionBlock.data.title}
                        description={actionBlock.data.description}
                        code={actionBlock.data.code}
                        image={actionBlock.data.image}
                        position={SEGMENT_POSITIONS.actionBlock}
                        promotionType={SEGMENT_PROMOTION_TYPE.actionBlock}
                        gated={!!(!isLoggedIn && enableGatedHomepage)}
                      />
                    ) : (
                      <PromoBlock
                        title={actionBlock.data.subtitle}
                        subtitle={actionBlock.data.title}
                        description={actionBlock.data.description}
                        link={actionBlock.data.button_link}
                        buttonText={actionBlock.data.button_title}
                        image={actionBlock.data.image}
                        position={SEGMENT_POSITIONS.actionBlock}
                        promotionType={SEGMENT_PROMOTION_TYPE.actionBlock}
                        gated={!!(!isLoggedIn && enableGatedHomepage)}
                      />
                    )}
                  </Content>
                </Box>
              )}

              {showInspirationalSection && (
                <Box order={inspirationalSection.position} as="section">
                  <RetroRevival items={inspirationalSection.data} />
                </Box>
              )}
              {popularCategories && showSection(!!popularCategories?.gated) && (
                <Box
                  pt={["space16", "space16", "space32", "space32"]}
                  pb={["space16", "space16", "space32", "space32"]}
                  order={popularCategories?.position}
                  backgroundColor="tone.sand"
                  as="section"
                >
                  <ContentMobileFullscreen>
                    <CategoryCarousel
                      genderId={localeGenderId}
                      title={t(i18n)`Popular categories`}
                      categories={categoryCarouselData}
                      color="tone.white"
                      headerLinkHref={`${getI18nRoute({
                        route: `/${localeGenderId}`,
                        locale,
                      })}/categories`}
                      slidesDesktop={categoryCarouselData.length}
                      slidesTablet={categoryCarouselData.length}
                      slidesMobile={2.5}
                    />
                  </ContentMobileFullscreen>
                </Box>
              )}
              {referFriend && (
                <Box
                  py="96px"
                  mx={["-20px", "-20px", "-20px", 0, 0]}
                  order={referFriend?.position}
                  backgroundColor="tone.sand"
                  as="section"
                >
                  <Content>
                    <Card
                      image={{
                        src: getImageWithUpdatedParameters(
                          referFriend.data.image,
                          {
                            width: "600",
                            height: "450",
                            focalPoint: "center",
                          }
                        ),
                        alt: "Refer a friend",
                      }}
                      withHorizontalImage
                      link={referFriend.data.link}
                      linkCTA={referFriend.data.action_button}
                      title={
                        <Box
                          textAlign={["left", "left", "center"]}
                          width={["100%", "100%", "100%", "276px"]}
                          as="span"
                          display="block"
                        >
                          {sanitizeString(referFriend.data.title)}
                        </Box>
                      }
                      titleForTracking={referFriend.data.title}
                      subTitle={
                        <Box
                          width={["100%", "100%", "100%", "276px"]}
                          display="block"
                          as="span"
                        >
                          {sanitizeString(referFriend.data.description)}
                        </Box>
                      }
                      position={SEGMENT_POSITIONS.referAFriend}
                      promotionType={SEGMENT_PROMOTION_TYPE.referAFriend}
                    />
                  </Content>
                </Box>
              )}
            </Flex>
          </NoSSR>
        )}
      </Box>
      <RRPFootnote />
    </>
  );
};

export default HomeView;
